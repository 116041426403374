import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Wordmark from "../images/brand-wordmark.png"
import style from "./header.module.scss"
import { MenuIcon } from "./menu-icon"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
    }
  }

  render() {
    const menuStyle = this.state.mobileOpen ? style.menuOpen : style.menuClosed

    return (
      <header className={style.header}>
        <Link to="/" className={style.tagline}>
          <h1 className={style.title}>Keep Local Alive</h1>
          <p className={style.copy}>
            Powered by{" "}
            <img src={Wordmark} alt="pao" className={style.wordmark} />
          </p>
        </Link>
        <MenuIcon
          className={style.menuIcon}
          onClick={this.handleOpen}
          onKeyUp={this.handleOpen}
        />
        <ul className={menuStyle}>
          <li>
            <Link to="/">Cities</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li className={style.bubbleLink}>
            <Link to="/list-your-business">List Your Business</Link>
          </li>
          <li className={style.mobileOnly} onClick={this.handleClose} onKeyUp={this.handleClose}>
            Close Menu
          </li>
        </ul>
      </header>
    )
  }

  handleClose = () => {
    this.setState({ mobileOpen: false })
  }

  handleOpen = () => {
    this.setState({ mobileOpen: true })
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
