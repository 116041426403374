/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { CallToAction } from "./call-to-action"
import { Footer } from "./footer"
import Header from "./header"
import { BackdropImage } from "./images/backdrop-image"
import style from "./layout.module.scss"
import "./layout.scss"
import SEO from "./seo"

const Layout = ({
  children,
  backgroundImage,
  title,
  description,
  seoImage,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title={title} ogImage={seoImage} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <BackdropImage
        filename={backgroundImage}
        style={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          opacity: 0.9,
          WebkitMaskImage:
            "linear-gradient(to top, transparent, rgba(0,0,0,0.5) 70%, transparent)",
          maskImage:
            "linear-gradient(to top, transparent, rgba(0,0,0,0.5) 70%, transparent)",
        }}
      />
      <div className={style.container}>
        <main>{children}</main>
        <CallToAction />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Layout
