import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const FollowImage = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { relativePath: { regex: "/^((?!(gdrive|bg-)).)*$/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed(width: 54) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }
      return (
        <Img
          alt={props.alt}
          fixed={image.node.childImageSharp.fixed}
          style={props.style}
          className={props.className}
        />
      )
    }}
  />
)

export { FollowImage }
