import * as React from "react"

const MenuIcon = ({ fill, className, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height={30}
    width={30}
    viewBox="0 0 384 384"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <rect x="0" y="277.333" width="384" height="42.667" fill={fill} />
          <rect x="0" y="170.667" width="384" height="42.667" fill={fill} />
          <rect x="0" y="64" width="384" height="42.667" fill={fill} />
        </g>
      </g>
    </g>
  </svg>
)

MenuIcon.defaultProps = {
  fill: "#FFFFFF",
}

export { MenuIcon }
