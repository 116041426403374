import * as React from "react"
import * as PropTypes from "prop-types"

export const ExternalLink = ({ href, className, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    {children}
  </a>
)

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
}
