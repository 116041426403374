import React from "react"
import style from "./footer.module.scss"

const Footer = () => (
  <footer className={style.footer}>
    <section className={style.linksSection}>
      <ul className={style.links}>
        <li>
          <a href="https://www.thepaoapp.com/terms-and-conditions/">
            Terms of Service
          </a>
        </li>
        <li>
          <a href="https://www.thepaoapp.com/privacy-policy/">Privacy Policy</a>
        </li>
        <li>
          <a href="mailto:admin@thepaoapp.com">Contact Us</a>
        </li>
      </ul>
    </section>
    <section className={style.copyright}>
      <p>{new Date().getFullYear()} Pao © All Rights Reserved</p>
    </section>
  </footer>
)

export { Footer }
