import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { locateDriveNode } from "./locate-drive-node"

const BackdropImage = props => (
  <StaticQuery
    query={graphql`
      query {
        googleDriveMapping: allDriveDownload {
          edges {
            node {
              name
              id
              googleId
              generatedFileName
            }
          }
        }
        images: allFile(
          filter: { relativePath: { regex: "/(bg-|city-|gdrive-cities).+/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      if (typeof props.filename !== "string") {
        return null
      }
      let image
      if (props.filename.includes("drive.google")) {
        image = locateDriveNode(data, props.filename)
      } else {
        image = data.images.edges.find(n => {
          return n.node.relativePath.includes(props.filename)
        })
      }
      if (!image) {
        return null
      }
      return (
        <Img
          className={props.className}
          alt={props.alt}
          fluid={image.node.childImageSharp.fluid}
          style={props.style}
        />
      )
    }}
  />
)

export { BackdropImage }
