export function extractDriveId(url) {
  let match = url.match(/https:\/\/drive.google.com\/open\?id=([^\/]+)/)
  if (match == null) {
    match = url.match(
      /https:\/\/drive.google.com(?:\/a\/[^\/]+)?\/file\/d\/([^\/]+)(\/view\?usp=sharing)?/
    )
  }
  if (match === null) {
    return null
  }
  return match[1]
}
