import * as React from "react"
import {
  appStoreLink,
  facebookLink,
  instagramLink,
  youTubeLink,
} from "../constants"
import { ExternalLink } from "./external-link"
import AppStoreBadge from "./images/app-store-badge"
import { FollowImage } from "./images/follow-image"
import WideImage from "./images/wide-image"
import style from "./call-to-action.module.scss"

const Action = ({ action, children, backgroundImage, className }) => (
  <div className={className}>
    <WideImage
      filename={backgroundImage}
      alt=""
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: "1",
        opacity: "60%",
      }}
    />
    <h1 className={style.action}>{action}</h1>
    <div className={style.actions}>{children}</div>
  </div>
)

export const CallToAction = () => (
  <section className={style.container}>
    <Action
      action={"Download The App"}
      backgroundImage="bg-download-app.png"
      className={style.downloadItem}
    >
      <ExternalLink href={appStoreLink}>
        <AppStoreBadge />
      </ExternalLink>
    </Action>
    <Action
      action={"Follow Us"}
      backgroundImage="bg-follow-us.png"
      className={style.followItem}
    >
      <div>
        <ExternalLink href={instagramLink}>
          <FollowImage filename="icon-instagram.png" alt="Instagram" />
        </ExternalLink>
      </div>
      <div>
        <ExternalLink href={youTubeLink}>
          <FollowImage filename="icon-youtube.png" alt="YouTube" />
        </ExternalLink>
      </div>
      <div>
        <ExternalLink href={facebookLink}>
          <FollowImage filename="icon-fb.png" alt="Facebook" />
        </ExternalLink>
      </div>
    </Action>
  </section>
)
