import { extractDriveId } from "../../utilities/extract-drive-id"

export function locateDriveNode(data, url) {
  const fileId = extractDriveId(url)
  if (fileId == null) {
    return null
  }
  const mappingNode = data.googleDriveMapping.edges.find(edge => {
    return edge.node.googleId === fileId
  })
  if (mappingNode == null) {
    return
  }
  const fileName = mappingNode.node.generatedFileName
  const imageNode = data.images.edges.find(n => {
    return n.node.relativePath.includes(fileName)
  })
  return imageNode
}
